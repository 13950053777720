.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.menu-bar {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.menu-bar button,
.menu-bar select {
  background: #f1f1f1;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
}

.menu-bar button.is-active {
  background: #e1e1e1;
}

.menu-bar input[type="color"] {
  height: 30px;
  width: 30px;
  padding: 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.ProseMirror {
  padding: 20px;
  min-height: 300px;
}

.ProseMirror:focus {
  outline: none;
}

.ProseMirror table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  margin: 0;
  overflow: hidden;
}

.ProseMirror td,
.ProseMirror th {
  min-width: 1em;
  border: 2px solid #ced4da;
  padding: 3px 5px;
  vertical-align: top;
  box-sizing: border-box;
  position: relative;
}

.ProseMirror .tableWrapper {
  margin: 1em 0;
  overflow-x: auto;
}

.ProseMirror .column-resize-handle {
  position: absolute;
  right: -2px;
  top: 0;
  bottom: -2px;
  width: 4px;
  background-color: #adf;
  pointer-events: none;
}

.ProseMirror table.no-border td,
.ProseMirror table.no-border th {
  border: none;
}

.ProseMirror img {
  max-width: 100%;
  height: auto;
}

.ProseMirror .bullet-list {
  list-style-type: disc;
}

.ProseMirror .ordered-list {
  list-style-type: decimal;
}

.tippy-items {
  background: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  font-size: 0.9rem;
}

.tippy-item {
  display: block;
  margin: 0;
  width: 100%;
  text-align: left;
  background: none;
  border: none;
  padding: 0.2rem 0.5rem;
  border-radius: 0.3rem;
}

.tippy-item.is-selected {
  background-color: #e1e1e1;
}

.ProseMirror {
  span[data-type="fieldSuggestion"] {
    color: blue;
    font-weight: bold;
  }
}
