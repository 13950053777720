@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,400i,600,700');
@import './_01_mixins.scss';
@import './_02_variables';
@import './_03_core.scss';
@import './_04_plugins.scss';
@import './_05_views.scss';

/** your custom css code **/
/* 
html {
    background-color: $theme-color-1 !important;
}
*/
.text-initial {
    text-align: initial !important;
}


body.active-modal {
    overflow-y: hidden;
}

.in-modal, .modal-overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.modal-overlay {
    background: rgba(49,49,49,0.8);
}
.in-modal-content {
    position: absolute;
    top: 10%;
    left: 20%;
    line-height: 1.4;
    background: #f1f1f1;
    padding: 1.5rem;
    border-radius: 3px;
    width: 70%;
    max-height: 60vh;
    overflow-y: auto;
}

.in-modal-content-bigger {
    position: absolute;
    top: 15%;
    left: 20%;
    line-height: 1.4;
    background: #f1f1f1;
    padding: 1.5rem;
    border-radius: 3px;
    width: 70%;
    max-height: 75vh;
    overflow-y: auto;
}

.sign-canvas {
    border: 1px solid gray;
    margin: 5px;
}

.clickable {
    cursor: pointer;
}

.clickable:hover {
    color:rgb(127, 80, 255);
}

#partner-switch {
    color: #00FFF6!important;    
    border-color: #00FFF6!important;
}

.processing-candidate {
    background: #FBFFB1!important;
}  