.calendar-container,
.calendar,
.calendar-header,
td,
tr {
  padding: 0;
  margin: 0;
}

.calendar-container {
  width: 375px;
  margin: auto;
}

.calendar {
  background-color: white;
  border-spacing: 0;
  border-collapse: collapse;
  padding: 0.5rem;
  width: 100%;
  border-radius: 0.75rem;
  box-shadow: 0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%);
}

.calendar-header {
  border-bottom: 1px dashed skyblue;
  font-size: 1.6em;
}

.calendar-header td {
  border-spacing: 0;
  padding-left: 5px;
}

.day,
.empty-slot,
.week-day {
  text-align: center;
  height: 40px;
}

.week-day {
  font-size: 0.8em;
}

.day {
  font-size: 0.8em;
}

.day {
  width: 24px;
  height: 24px;
  line-height: 24px;
}

.current-day {
  background-color: #4285f4;
  width: 28px;
  height: 28px;
  line-height: 28px;
  border-radius: 2%;
  color: white;
}

.available-day {
  cursor: pointer;
  background-color: #e6e6e6;
  border-bottom: 3px solid #28a745 !important;
  width: 28px;
  height: 28px;
  line-height: 28px;
  border-radius: 2%;
  color: black;
}

.selected-day {
  background-color: #008ecc !important;
  border-bottom: 0px !important;
  width: 28px;
  height: 28px;
  line-height: 28px;
  border-radius: 2%;
  color: black;
}

.month-popup {
  position: absolute;
  padding: 5px;
  background: white;
  border: 2px solid skyblue;
}

.month-popup div {
  padding: 0.3em;
}

.month-click {
  cursor: pointer;
  font-size: 0.8rem;
  color: #212529;
}

.nav-month {
  position: absolute;
  top: 0.6em;
  font-size: 0.6em;
  right: 3px;
}

.prev {
}

.next {
}

.editor-year {
  max-width: 3.6em;
}

.label-month,
.label-year {
  font-size: 0.6em;
}

.label-month {
  cursor: pointer;
}
